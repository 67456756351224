<template>
    <div>
        <span>
            <i class="fa fa-circle text-secondary ml-3 small"/>
            <span class="text-secondary font-weight-bold">Personal Info
                <i class="fa fa-angle-double-right ml-2"/>
            </span>
        </span>
        <span>
            <i class="fa fa-circle text-primary ml-3 small"/>
            <span class="text-primary font-weight-bold">Qualification Info</span>
        </span>
        <span>
            <i class="fa fa-circle text-secondary ml-3 small"/>
            <span class="text-secondary font-weight-bold">Reache Info</span>
        </span>
        <perfect-scrollbar>
            <s-form class="p-4" @submit="submitClicked" ref="form">
                <h5>Qualification Info</h5>
                <div class="row">
                    <div class="col">
                        <validated-ajax-vue-select :url="countryOptions"
                                                   label="Primary medical qualification country"
                                                   name="Primary medical qualification country"
                                                   v-model="model.primary_medical_qualification_country_id"
                                                   :rules="{required : true}"
                                                   :disabled="loading"/>
                    </div>
                    <div class="col">
                        <validated-input label="Caring Responsibilities" name="Caring responsibility"
                                         v-model="model.caring_responsibilities"
                                         :rules="{required : true}"
                                         :disabled="loading"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <validated-vue-select label="Disability" name="Disability"
                                              v-model="model.disability"
                                              :rules="{required : true}" :disabled="loading"
                                              :options="booleanOptions"/>
                    </div>
                    <div class="col">
                        <validated-vue-select label="Ethnicity" name="Ethnicity" v-model="model.ethnicity"
                                              :rules="{required : true}" :disabled="loading"
                                              :options="booleanOptions"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <validated-input label="Additional Educational Needs"
                                         name="Educational needs"
                                         v-model="model.additional_educational_needs"
                                         :rules="{required : true}"
                                         :disabled="loading"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <validated-vue-select :options="qualificationsOptions"
                                              label="Professional Qualifications"
                                              name="Qualification"
                                              v-model="model.professional_qualifications"
                                              :rules="{required : true}"
                                              :disabled="loading"/>
                    </div>
                    <div class="col-2" style="margin-top: 2rem;">
                        <btn type="button" color="success" text="Add" @click="$refs.addQualificationModal.show()"
                             icon="fa fa-plus" size="sm"/>
                    </div>
                </div>
                <div class="fl-x-bc mt-3 mb-3">
                    <btn size="sm" @click="backClicked" text="Back" type="button"/>
                    <btn size="sm" text="Continue" class="ml-2" :loading="loading" loading-text="Processing..."/>
                </div>
            </s-form>
        </perfect-scrollbar>
        <modal class="c-modal no-close" title="Add Qualification" ref="addQualificationModal" width="30r"
               header-color="">
            <s-form @submit="submitQualification" ref="form">
                <validated-input label="Profession" name="Profession" v-model="model1.name"
                                 :rules="{required : true}"
                                 :disabled="loading1"/>
                <btn size="sm" text="Save" :loading="loading1" loading-text="Saving.."/>
            </s-form>
        </modal>
    </div>
</template>

<script>

import axios from 'secure-axios';
import urls from '../../../../data/urls';

export default {
    name: 'RegistrationQualificationForm',
    props: { data: { type: Object } },
    data () {
        return {
            loading1: false,
            loading: false,
            action: '',
            id: '',
            addUrl: urls.auth.register.qualificationDetails.add,
            editUrl: urls.auth.register.qualificationDetails.edit,
            edit: urls.auth.register.qualificationDetails.edit,
            countryOptions: urls.vueSelect.countryOptions,
            qualificationsOptions: [],
            languageOptions: urls.vueSelect.languageOptions,
            booleanOptions: [
                { value: 0, label: 'Yes' },
                { value: 1, label: 'No' }
            ],
            model: {},
            model1: {}
        };
    },
    mounted () {
        this.loadQualification();
    },
    methods: {
        backClicked () {
            this.$emit('back', this.data);
        },
        loadQualification () {
            const that = this;
            axios.post(urls.vueSelect.qualificationsOptions).then(function (response) {
                that.qualificationsOptions = response.data.data;
            });
        },
        async submitQualification () {
            const that = this;
            that.loading1 = true;
            const response = await axios.form(urls.admin.masterData.profession.add, that.model1);
            const json = response.data;
            if (json.error === false) {
                that.formSuccess();
                that.loading1 = false;
            } else {
                that.formError(json);
                that.loading1 = false;
            }
            that.loading1 = false;
        },
        async submitClicked () {
            const that = this;
            that.loading = true;
            if (that.data.id) {
                that.action = that.editUrl;
                that.model.id = that.data.id;
            } else {
                that.action = that.addUrl;
            }
            const response = await axios.form(that.action, that.model);
            const json = response.data;
            if (json.error === false) {
                that.loading = false;
                that.formSuccess1(json);
            } else {
                that.formError(json);
                that.loading = false;
            }
            that.loading = false;
        },
        formSuccess1 (response) {
            this.data = response.data;
            this.$emit('continue', this.data);
        },
        formSuccess () {
            this.$refs.addQualificationModal.close();
            this.$notify('Added Successfully', 'Success', {
                type: 'success'
            });
            this.loadQualification();
        },
        formError (json) {
            this.$refs.form.setErrors(json.errors);
            this.$notify('Please fix the issue.', 'Error', {
                type: 'danger'
            });
        }
    }
};
</script>

<style>
.full-page {
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.image {
    background: url("../../../../assets/img/banner-blurred.png");
}

.link {
    cursor: pointer;
}
</style>
