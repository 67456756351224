<template>
    <div>
        <span>
            <i class="fa fa-circle text-primary ml-3 small"/>
            <span class="text-primary font-weight-bold">Personal Info
                <i class="fa fa-angle-double-right ml-2"/>
            </span>
        </span>
        <span>
            <i class="fa fa-circle text-secondary ml-3 small"/>
            <span class="text-secondary font-weight-bold">Qualification Info</span>
        </span>
        <span>
            <i class="fa fa-circle text-secondary ml-3 small"/>
            <span class="text-secondary font-weight-bold">Reache Info</span>
        </span>
        <perfect-scrollbar>
            <s-form class="p-4" @submit="submitClicked" ref="form">
                <h5>Basic Info</h5>
                <div class="row">
                    <div class="col">
                        <validated-input label="Name" name="Name" v-model="model.name"
                                         :rules="{required : true}"
                                         :disabled="loading"/>
                    </div>
                    <div class="col">
                        <validated-vue-select label="Gender" name="Gender" v-model="model.gender"
                                              :rules="{required : true}" :disabled="loading"
                                              :options="genderOptions"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <validated-input type="email" label="Email" name="E-Mail" v-model="model.email"
                                         :rules="{required : true}" :disabled="loading"/>
                    </div>
                    <div class="col">
                        <textarea-input label="Address" name="Address" v-model="model.address"
                                        :disabled="loading" :rules="{required : true}"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <validated-date-picker label="DOB" name="Date of birth"
                                               v-model="model.date_of_birth"
                                               :disabled="loading" :rules="{required : true}"/>
                    </div>
                    <div class="col">
                        <validated-input label="Age" name="Age" v-model="model.age"
                                         :rules="{required : true}"
                                         :disabled="loading" type="number"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <validated-file-input label="Profile Image" name="Profile image"
                                              v-model="model.profile_image"
                                              :disabled="loading"/>
                    </div>
                    <div class="col">
                        <validated-ajax-vue-select :url="religionOptions" label="Religion" name="Religion"
                                                   v-model="model.religion" :rules="{required : true}"
                                                   :disabled="loading"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <validated-ajax-vue-select :url="countryOptions" label="Country Of Origin"
                                                   name="Country of origin"
                                                   v-model="model.country_of_origin"
                                                   :rules="{required : true}"
                                                   :disabled="loading"/>
                    </div>
                    <div class="col">
                        <validated-ajax-vue-select :url="languageOptions" label="Language Spoken"
                                                   name="Language spoken"
                                                   v-model="model.language_spoken"
                                                   :rules="{required : true}"
                                                   :disabled="loading"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <validated-input label="Dependents" name="Dependents" v-model="model.dependents"
                                         :rules="{required : true}"
                                         :disabled="loading"/>
                    </div>
                    <div class="col">
                        <validated-vue-select label="Immigration Status" name="Immigration status"
                                              v-model="model.immigration_status"
                                              :rules="{required : true}" :disabled="loading"
                                              :options="immigrationOptions"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <validated-date-picker label="Date Of Arrival" name="Date of arrival"
                                               v-model="model.date_of_arrival"
                                               :disabled="loading" :rules="{required : true}"/>
                    </div>
                    <div class="col">
                        <validated-vue-select label="Branch" name="Branch" v-model="model.branch"
                                              :options="branchOptions" :rules="{required : true}"
                                              :disabled="loading"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <validated-input label="Password" name="Password" v-model="model.password"
                                         :rules="{required : true}"
                                         :disabled="loading" type="password"/>
                    </div>
                    <div class="col">
                        <validated-input label="Confirm Password" name="Confirm password"
                                         v-model="model.confirm_password"
                                         :rules="{required : true}" @blur="validatePassword"
                                         :disabled="loading" type="password"/>
                    </div>
                </div>
                <p style="color: red;">{{ errorPassMsg }}</p>
                <div class="fl-x-bc mt-3 mb-3">
                    <btn size="sm" text="Continue" :loading="loading" loading-text="Processing..."/>
                </div>
            </s-form>
        </perfect-scrollbar>
    </div>
</template>

<script>

import urls from '../../../../data/urls';
import axios from 'secure-axios';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';

export default {
    name: 'RegistrationBasicForm',
    props: { data: { type: Object } },
    data () {
        return {
            components: { PerfectScrollbar },
            loading: false,
            id: '',
            action: '',
            add: urls.auth.register.basicDetails.add,
            edit: urls.auth.register.basicDetails.edit,
            countryOptions: urls.vueSelect.countryOptions,
            qualificationsOptions: urls.vueSelect.qualificationsOptions,
            languageOptions: urls.vueSelect.languageOptions,
            religionOptions: urls.vueSelect.religionOptions,
            branchOptions: [
                { value: 'Clinical', label: 'Clinical' },
                { value: 'OET', label: 'OET' },
                { value: 'Volunteer', label: 'Volunteer' }
            ],
            immigrationOptions: [
                { value: 'Citizens', label: 'Citizens' },
                { value: 'Residents', label: 'Residents' },
                { value: 'Non-Immigrants', label: 'Non-Immigrants' },
                { value: 'Undocumented Immigrants', label: 'Undocumented Immigrants' }
            ],
            genderOptions: [
                { value: 'Male', label: 'Male' },
                { value: 'Female', label: 'Female' },
                { value: 'Trans', label: 'Trans' },
                { value: 'Non-Conforming', label: 'Non-Conforming' },
                { value: 'Personal', label: 'Personal' },
                { value: 'Eunuch', label: 'Eunuch' }
            ],
            errorPassMsg: '',
            // model: {
            //     name: 'Sanjay',
            //     gender: 'Male',
            //     email: 'sanjay@mail.com',
            //     address: 'Address 1',
            //     date_of_birth: '1999-10-04',
            //     age: '22',
            //     profile_image: '',
            //     religion: '1',
            //     country_of_origin: '1',
            //     language_spoken: '1',
            //     dependents: 'No Dependents',
            //     immigration_status: 'Citizens',
            //     date_of_arrival: '1999-10-04',
            //     branch: 'Clinical',
            //     password: 'sanjay',
            //     confirm_password: 'sanjay'
            // },
            model: {}
        };
    },
    mounted () {
        if (this.data) {
            this.model = this.data;
        }
    },
    methods: {
        async submitClicked () {
            const that = this;
            that.loading = true;
            if (that.data.id) {
                that.action = that.edit;
                that.model.id = that.data.id;
            } else {
                that.action = that.add;
            }
            const response = await axios.form(that.action, that.model);
            const json = response.data;
            if (json.error === false) {
                that.loading = false;
                that.formSuccess(json);
            } else {
                that.formError(json);
                that.loading = false;
            }
            that.loading = false;
        },
        formSuccess (response) {
            this.data = response.data;
            this.$emit('continue', this.data);
        },
        formError (json) {
            this.$refs.form.setErrors(json.errors);
            this.$notify('Please fix the issue.', 'Error', {
                type: 'danger'
            });
        },
        validatePassword () {
            const pass1 = this.model.password;
            const pass2 = this.model.confirm_password;
            if (pass1 !== pass2) {
                this.errorPassMsg = 'Password Mismatch';
            } else {
                this.errorPassMsg = '';
            }
        }
    }
};
</script>

<style>
.full-page {
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.image {
    background: url('../../../../assets/img/banner-blurred.png');
}

.link {
    cursor: pointer;
}
</style>
