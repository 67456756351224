<template>
    <div class="full-page image">
        <div class="w-80r fl-eq" style="height: 40rem;">
            <div class="bg-primary" style="width: 20%;">
                <img class="mt-7 p-8" src="../../../assets/img/logo-w.png" alt="logo">
            </div>
            <div class="pl-3 pt-5 bg-2" style="width: 50%;">
                <h3 class="p-3 text-primary">Registration</h3>
                <RegistrationBasicForm @continue="changeScreen" :data="data" v-show="basicSection" ref="basicInfoRegistration"/>
                <RegistrationQualificationForm @back="backClicked" :data="data" v-show="qualificationSection" ref="qualificationInfoRegistration"/>
            </div>
        </div>
    </div>
</template>

<script>

import RegistrationBasicForm from './Components/BasicDetails';
import RegistrationQualificationForm from './Components/QualifIcation';

export default {
    name: 'Registration',
    components: { RegistrationQualificationForm, RegistrationBasicForm },
    data () {
        return {
            loading: false,
            basicSection: true,
            data: '',
            qualificationSection: false,
            model: {},
            scrollHeight: '450px'
        };
    },
    methods: {
        changeScreen (response) {
            this.data = response;
            this.basicSection = false;
            this.qualificationSection = true;
        },
        backClicked (response) {
            this.data = response;
            this.basicSection = true;
            this.qualificationSection = false;
        }
    }
};
</script>

<style>
.ps {
    height: 450px;
}

.ps__rail-x {
    display: none !important;
}

.full-page {
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.image {
    background: url("../../../assets/img/banner-blurred.png");
}

.link {
    cursor: pointer;
}
</style>
